export const CREDENTIALS = {
    "gr.alpha": [
        {user: "aathanasiou", pw: "AAthanasiou05", otp: "112233"},
        {user: "gpapadopoulos", pw: "GPapadopoulos01", otp: "112233"},
        {user: "6ae", pw: "AEtaireia02", otp: "112233"},
        {user: "pgeorgiou", pw: "PGeorgiou03", otp: "112233"},
        {user: "giwannou", pw: "GIwannou04", otp: "112233"},
        {user: "mmariou", pw: "MMAriou06", otp: "112233"},
        {user: "aanastasiou", pw: "AAnastasiou07", otp: "112233"},
        {user: "eeuaggelou", pw: "EEuaggelou08", otp: "112233"},
        {user: "sstaurou", pw: "SStaurou09", otp: "112233"},
        {user: "anastasis", pw: "AAnastasiou10", otp: "112233"},
        {user: "kkwnstantinou", pw: "KKwnstantinou11", otp: "112233"},
        {user: "ddimitriou", pw: "DDimitriou12", otp: "112233"},
        {user: "mmatthaiou", pw: "MMatthaiou13", otp: "112233"},
    ],
    "gr.piraeus": [
        {user: "UserA", pw: "123", otp: "3288000"},
        {user: "UserB", pw: "123", otp: "3288000"},
        {user: "UserC", pw: "123", otp: "3288000"}
    ],
    "gr.nbg": [{user: "aalexandrakis", pw: "Asd123!."}],
    "gr.eurobank": [
        {user: "userNameA", pw: "passA", otp: "111111"},
        {user: "userNameB", pw: "passB", otp: "222222"},
        {user: "userNameC", pw: "passC", otp: "222222"}
    ],
    "gr.optima": [{user: "user2", pw: "password2"}],
    "cy.eurobank": [{user: "343919", pw: "Test1234"}],
    "cy.ancoria": [{user: "user2", pw: "password2"}],
    "cy.rcb": [{user: "user2", pw: "password2"}],
    "cy.cdb": [{user: "user2", pw: "password2"}],
    "lb.bbac": [{user: "user2", pw: "password2"}],
    "cy.nbg": [{user: "user2", pw: "password2"}],
    "cy.boc": [{user: "999999", pw: "112233", otp: "111111"}],
    "cy.hellenic": [{user: "123456", pw: "password", otp: "123456"}],
    "cy.alpha": [
        {user: "aathanasiou", pw: "AAthanasiou05", otp: "112233"},
        {user: "gpapadopoulos", pw: "GPapadopoulos01", otp: "112233"},
        {user: "6ae", pw: "AEtaireia02", otp: "112233"},
        {user: "pgeorgiou", pw: "PGeorgiou03", otp: "112233"},
        {user: "giwannou", pw: "GIwannou04", otp: "112233"},
        {user: "mmariou", pw: "MMAriou06", otp: "112233"},
        {user: "aanastasiou", pw: "AAnastasiou07", otp: "112233"},
        {user: "eeuaggelou", pw: "EEuaggelou08", otp: "112233"},
        {user: "sstaurou", pw: "SStaurou09", otp: "112233"},
        {user: "anastasis", pw: "AAnastasiou10", otp: "112233"},
        {user: "kkwnstantinou", pw: "KKwnstantinou11", otp: "112233"},
        {user: "ddimitriou", pw: "DDimitriou12", otp: "112233"},
        {user: "mmatthaiou", pw: "MMatthaiou13", otp: "112233"},
    ],
    "uk.natwest": [{user: "123456789012", pw: "-", otp: "-"}],
    "uk.n26": [{user: 'Click "Proceed as Guest"', pw: "-", otp: "123456"}],
    "uk.hsbc": [{user: 'uk.retail', pw: "123456", otp: "-"}],
    "uk.revolut": [{user: '+44 7234961650', pw: "0000", otp: "-"}]
}

export const PROVIDERS = {
    "paymentcomponents": {name: "Payment Components", idx: "paymentcomponents", provider: "paymentcomponents"},
    "cy.boc": {name: "Bank of Cyprus", idx: "boc"},
    "cy.ancoria": {name: "Ancoria Bank", idx: "ancoria"},
    "cy.rcb": {name: "RCB", idx: "rcb"},
    "cy.cdb": {name: "CDB", idx: "cdb"},
    "lb.bbac": {name: "BBAC", idx: "bbac"},
    "cy.nbg": {name: "NBG Cyprus", idx: "nbg-cy"},
    "cy.hellenic": {name: "Hellenic Bank", idx: "hellenic"},
    "cy.alpha": {name: "Alpha Bank Cyprus", idx: "alpha-cy"},
    "cy.eurobank": {name: "Eurobank Cyprus", idx: "eurobank-cy"},
    "gr.eurobank": {name: "Eurobank Greece", idx: "eurobank-gr"},
    "gr.piraeus": {name: "Piraeus Bank", idx: "piraeus"},
    "gr.alpha": {name: "Alpha Bank Greece", idx: "alpha-gr"},
    "gr.nbg": {name: "NBG", idx: "nbg"},
    "gr.optima": {name: "Optima", idx: "optima"},
    "uk.natwest": {name: "NatWest", idx: "natwest"},
    "uk.hsbc": {name: "HSBC", idx: "hsbc"},
    "uk.n26": {name: "N26", idx: "n26"},
    "uk.revolut": {name: "Revolut", idx: "revolut"},
    "boc": {provider: "cy.boc"},
    "ancoria": {provider: "cy.ancoria"},
    "rcb": {provider: "cy.rcb"},
    "cdb": {provider: "cy.cdb"},
    "bbac": {provider: "lb.bbac"},
    "nbg-cy": {provider: "cy.nbg"},
    "hellenic": {provider: "cy.hellenic"},
    "alpha-cy": {provider: "cy.alpha"},
    "eurobank-cy": {provider: "cy.eurobank"},
    "eurobank-gr": {provider: "gr.eurobank"},
    "piraeus": {provider: "gr.piraeus"},
    "alpha-gr": {provider: "gr.alpha"},
    "nbg": {provider: "gr.nbg"},
    "optima": {provider: "gr.optima"},
    "natwest": {provider: "uk.natwest"},
    "hsbc": {provider: "uk.hsbc"},
    "n26": {provider: "uk.n26"},
    "revolut": {provider: "uk.revolut"}
};


export const PROVIDERS_PATHS = {
    "paymentcomponents": {provider: "paymentcomponents"},
    "boc": {provider: "cy.boc"},
    "ancoria": {provider: "cy.ancoria"},
    "rcb": {provider: "cy.rcb"},
    "cdb": {provider: "cy.cdb"},
    "bbac": {provider: "lb.bbac"},
    "optima": {provider: "gr.optima"},
    "nbg-cy": {provider: "cy.nbg"},
    "hellenic": {provider: "cy.hellenic"},
    "eurobank-cy": {provider: "cy.eurobank"},
    "eurobank-gr": {provider: "gr.eurobank"},
    "piraeus": {provider: "gr.piraeus"},
    "alpha-gr": {provider: "gr.alpha"},
    "alpha-cy": {provider: "cy.alpha"},
    "nbg": {provider: "gr.nbg"},
    "natwest": {provider: "uk.natwest"},
    "hsbc": {provider: "uk.hsbc"},
    "n26": {provider: "uk.n26"},
    "revolut": {provider: "uk.revolut"}
};

export const OTP_METHODS = {
    SMS_OTP: {message: 'Type your extra PIN provided to your declared phone number'},
    CHIP_OTP: {message: 'Type the PIN that is generated by an appropriate device'},
    PUSH_OTP: {message: "Type the PIN that is provided by provider's mobile app"}
};

export const ACCOUNT_LABELS = {
    currency: "Currency",
    iban: "IBAN",
    bban: "BBAN",
    name: "Name",
    resourceId: "Resourse ID",
    balances: "Balances",
    amount: "Amount",
    balanceAmount: "Balance Amount",
    _links: "Links",
    transactions: "Transactions",
    booked: "Booked",
    pending: "Pending",
    additionalInformation: "Additional Information",
    debtorName: "Debtor Name",
    transactionId: "Transaction ID",
    account: "Account",
    href: "Link"
};

export const CURRENCIES = [
    'EUR',
    'USD',
    'GBP'
    // 'CHF',
    // 'CAD',
    // 'AUD',
    // 'NZD',
    // 'JPY'
];

export const BANKS_DEBTOR_ACCOUNT = [
    'gr.eurobank',
    'gr.piraeus'
];

export const PAYMENTS_BODY = (bankId, data) => {
    return data ? {
        payment: {
            endToEndIdentification: data['endToEndId'],
            ...(BANKS_DEBTOR_ACCOUNT.includes(bankId) &&
                {
                    debtorAccount: {
                        iban: data['debtorAccountIban']
                    }
                }),
            instructedAmount: {
                currency: data['currency'],
                amount: data['amount']
            },
            creditorAccount: {
                iban: data['creditorAccountIban']
            },
            creditorName: data['creditorName'],
            remittanceInformationUnstructured: data['remittanceInformation']
        }
    } : null;
}

export const AUTOFILL_PAYMENT_DATA = {
    'gr.alpha': {
        domestic: {
            'creditorAccountIban': 'GR9202600260000000000299650',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        },
        international: {
            'creditorAccountIban': 'GB29NWBK60161331926819',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1',
            'currency': 'GBP',
            'remittanceInformation': 'Testing payments'
        }
    },
    'gr.piraeus': {
        noConsent: {
            'creditorAccountIban': 'GR9001718220007025111222333',
            'creditorName': 'Creditor Name',
            'endToEndId': '249028e6-50d4-403e-9a20-34cd96a54a',
            'amount': '2',
            'currency': 'EUR',
            'debtorAccountIban': 'GR2801718220007022123123123',
            'remittanceInformation': 'Ref Number Merchant'
        },
        withConsent: {
            'creditorAccountIban': 'GR0101718220007027000112113',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '2',
            'currency': 'EUR',
            'debtorAccountIban': 'GR2801718220007022123123123',
            'remittanceInformation': 'Ref Number Merchant'
        },
    },
    'gr.nbg': {
        domestic: {
            'creditorAccountIban': 'GR0101718220007027000112113',
            'creditorName': 'Creditor Name',
            'endToEndId': '399ccee1-e41e-42f8-b64b-632472fd87a',
            'amount': '1',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        },
        international: {
            'creditorAccountIban': 'GB29NWBK60161331926819',
            'creditorName': 'Creditor Name',
            'endToEndId': '399ccee1-e41e-42f8-b64b-632472fd87a',
            'amount': '1',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        }
    },
    'gr.eurobank': {
        domestic: {
            'creditorAccountIban': 'GR2302606770000140103482297',
            'creditorName': 'Creditor Name',
            'endToEndId': 'd494486d-9899-43d4-80d8-9b5db1cbd6',
            'amount': '12',
            'currency': 'EUR',
            'debtorAccountIban': 'GR5702600020000630201988841',
            'remittanceInformation': 'Testing payments'
        }
    },
    'gr.optima': {
        domestic: {
            'creditorAccountIban': 'GR6111100010012345678901237',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        }
    },
    'cy.ancoria': {
        domestic: {
            'creditorAccountIban': 'GR9202600260000000000299650',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        }
    },
    'cy.rcb': {
        domestic: {
            'creditorAccountIban': 'GR9202600260000000000299650',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        }
    },
    'cy.cdb': {
        domestic: {
            'creditorAccountIban': 'GR9202600260000000000299650',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        }
    },
    'cy.nbg': {
        domestic: {
            'creditorAccountIban': 'GR9202600260000000000299650',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        }
    },
    'cy.alpha': {
        domestic: {
            'creditorAccountIban': 'GR9202600260000000000299650',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        },
        international: {
            'creditorAccountIban': 'GB29NWBK60161331926819',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1',
            'currency': 'GBP',
            'remittanceInformation': 'Testing payments'
        }
    },
    'cy.hellenic': {
        domestic: {
            'creditorAccountIban': 'GR9202600260000000000299650',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        }
    },
    'cy.boc': {
        domestic: {
            'creditorAccountIban': 'GR7501102520000025275533583',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        }
    },
    'lb.bbac': {
        domestic: {
            'creditorAccountIban': 'GR9202600260000000000299650',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        }
    },
    'uk.natwest': {
        domestic: {
            'creditorAccountIban': 'BE56456394728288',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'GBP',
            'remittanceInformation': 'Testing payments'
        },
        international: {
            'creditorAccountIban': 'IE64BOFI90583812345678',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'EUR',
            'remittanceInformation': 'Testing payments'
        }
    },
    'uk.revolut': {
        domestic: {
            'creditorAccountIban': 'GB33BUKB20201555555555',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.00',
            'currency': 'GBP',
            'remittanceInformation': 'Testing payments'
        },
        international: {
            'creditorAccountIban': 'GB33BUKB20201555555555',
            'creditorName': 'Beneficiary',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.0',
            'currency': 'USD',
            'remittanceInformation': 'Testing payments'
        }
    },
    'uk.hsbc': {
        domestic: {
            'creditorAccountIban': 'GB22HBUK40000012345677',
            'creditorName': 'Creditor Name',
            'endToEndId': '346c7cad-c06e-43b6-8d17-7531901b692',
            'amount': '1.0',
            'currency': 'GBP',
            'remittanceInformation': 'Testing payments'
        }
    }

}
